import * as React from "react";
import { Container, Header } from "semantic-ui-react";
import Layout from "../components/Layout";

// data
const IndexPage = () => {
  return (
    <Layout page="home">
      <Container>
        <div className="mt5">
          <Header as="h1" dividing>
            Welcome to 27yrs.com
          </Header>
          <p>
            The 27 Club is a list consisting mostly of popular musicians,
            artists, or actors who died at age 27. Although the claim of a
            "statistical spike" for the death of musicians at that age has been
            repeatedly disproven by research, it remains a cultural phenomenon,
            documenting the deaths of celebrities, some noted for their
            high-risk lifestyles. Names are often put forward for inclusion, but
            because the club is entirely notional, there is no official
            membership.
          </p>
        </div>
      </Container>
    </Layout>
  );
};

export default IndexPage;
